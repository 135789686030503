.live-data-widget-container.has-solar {
  width: 26rem;
}
.live-data-widget-container {
  background-color: #96D465;
  color: #0b235a;
  height: 100%;
  width: 12rem;
}
.live-data-widget {
  padding-top: .5rem;
  padding-right: 1rem;
  text-align: left;
}
.live-data-measure {
  float: left;
  text-align: right;
  margin-top: .2rem;
  padding-right: .6rem;
  width: 90px;
  height: 38px;
}
.live-data-widget-container.has-solar .live-data-measure-solar{
  border-right: 1px solid #fff;
}
.live-data-widget-container.has-solar .live-data-measure-consumption{
  border-right: 1px solid #fff;
}
.live-data-widget-label {
  font-size: 80%;
}
.live-data-widget-value {
  font-weight: 555;
  padding-bottom: .5rem;
  width: 4rem;
}
.live-data-widget .histogram {
  transition: height 0.2s;
}
.live-data-widget {
  transition: opacity 1s;
  margin-left: 10px;
}
.live-loading {
  opacity: 0;
  height: 0; overflow: hidden;
}
.live-loaded {
  opacity: 1;
}

.live-data-widget-container .erroring-widget {
  background: none;
  margin: 0;
  padding: 0.4rem;
  padding-right: 2rem;
}
.live-data-widget-container .loading-widget {
   background: none;
   margin: 0;
   padding: 0.1em;
   font-size: smaller;
   animation: opacity-animation 2s ease-in-out infinite;
   -webkit-animation: opacity-animation 2s ease-in-out infinite;
}
@keyframes opacity-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes opacity-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}

.live-data-widget-container .loading-screen__spinner {
  display: none;
}