.spinning-ring {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
}
.spinning-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
  border: 0.5rem solid #FFFFFF;
  border-radius: 50%;
  animation: spinning-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--elevation-white) transparent transparent transparent;
}
.spinning-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinning-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinning-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinning-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-screen {
  text-align: center;
  margin: 0 auto;
  padding: 0;
  z-index: 99;
}

.vanish {
  opacity: 0.0;
  transition: opacity 1.0s ease-in-out;
  pointer-events: pass-through;
}
.appear {
  opacity: 1;
  transition: opacity 1.0s ease-in-out;
  pointer-events: all;
}

.loading-widget .spinning-ring div {
  border-color: var(--elevation-dark-blue) transparent transparent transparent;
}

.erroring-widget__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  width: 100%;
}
.erroring-widget__content.header {
  height: 100%;
  display: flex;
  vertical-align: middle;
}