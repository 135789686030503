.accordianContent h3, .registerBox h3 {
    cursor: pointer;
}
.accordianContent {
    transition: max-height 1s ease;
    overflow: hidden;
}
.registerBox {
    
    flex-grow: 2;
    padding: 4px;
    border: 2px solid var(--elevation-light-blue);
    border-radius: 10px;
    margin: 10px 10px 10px 10px;
}
.accordianContent.tall {
  max-height: 500px;
  height: auto;
  min-height: auto;
}
.accordianContent.short{
    max-height: 0;
}
.aspect-value {
    text-align: right;
    color: var(--elevation-green);
}
.aspect-label{
    font-weight: bold;
    color: var(--elevation-dark-blue);
}

.accordian-aspect-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px 0 20px;
    padding: 6px 0px 2px 0px;
    border-bottom: 1px solid var(--elevation-light-grey);
    font-size: var(--font-size-sm);
    color: var(--elevation-dark-blue);
}
.accordian-aspect-row:last-child {
    border-bottom: none;
}




.insightsPage {
    display: flex;
    flex-direction: row;
  }
@media screen and (max-width: 50rem) {
    .insightsPage {
        flex-direction: column;
    }
    
}
  
  .ctaBox {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px 20px 10px 20px;
    border: 2px solid var(--elevation-light-blue);
    border-radius: 10px;
  }
  
  .ctaBox img {
    border-radius: 10px;
    height: 30%;
    margin-bottom: 20px;
  }
  .ctaBox .ctaButton {
    background: linear-gradient(90deg, var(--elevation-blue), var(--elevation-green));
    color: white;
    text-align: left;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 13px;
  }
  .ctaBox .ctaButton:after {
    content: "→";
    float: right;
  }



  .ctaBox{
    position: relative;
    height: fit-content;
  }
  .ctaBox:after {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top:-1px;
    bottom:-1px;
    left: -1px;
    right: -1px;
    content: " ";
    opacity: 50%;
    border-radius: 10px;
    border: 2px solid var(--elevation-light-blue);
  }
  .ctaBox.ctaOne:after {
    background: var(--elevation-green);
    border: 1px solid var(--elevation-green);

  }
  .ctaBox.ctaTwo:after {
    background: var(--elevation-light-blue);
  }
  .ctaBox.ctaOne {
    border-color: var(--elevation-green);
  }

  .insightsPage h1, .insightsPage h4 {
    font-size: var(--font-size-base);
    text-transform: uppercase;
  }
  .insightsDasboard h1 {
    margin: 20px;
    padding: 10px 0 10px 0;
    
  }

  .insightsCopy {
    color: var(--elevation-blue);
    margin: 20px;
    padding: 10px 0 10px 0;
    /* padding: 0 20px 0px 10px; */
  }

  .insightsDasboard {
    margin: 10px;
    padding: 0 10px 0 10px;
    border: 2px solid var(--elevation-light-blue);
    border-radius: 10px;
  }

  .registerBox h3 {
    margin-left: 20px;
  }





.angleBracketHolder svg {
    height: inherit;
    /* height: 1em; */
    overflow: hidden;
  }
  .angleBracketHolder {
    height: 1.5em;
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-top: -10px;
  }

  .angleBracketHolder.open {
    transform: rotate(270deg);
  }

  .angleBracketHolder.closed {
    transform: rotate(90deg);
  }

  .center {
    align-items: center;
  }


.toggleBox {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .registerBox h3 {
    color: var(--elevation-dark-blue);
  }

  .toggleBox h4 {
    color: var(--elevation-green);
    text-align: right;
  }

 .toggleBox h3 {
    font-size: var(--font-size-base);
  }

  .opaque{opacity:1}
    .transparent{opacity:0.0}
  .toggleBox h4 {
    text-transform: none;
    font-size: var(--font-size-base);
    transition: opacity 0.3s ease;
  }